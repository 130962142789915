/** @jsx jsx **/
import { Box, Container, Heading, Text, jsx } from 'theme-ui'
import { Fragment } from 'react'
import { space } from '~/gatsby-plugin-theme-ui'

import { ArticlePageInterface, PagePropsInterface } from '~/@types/models'
import BaseTypo from '~/components/base-typo'
import HeroImageSection from '~/components/hero-image-section'
import Layout from '~/components/layout'
import RecentArticles from '~/componentsV2/recent-articles'

const Article = ({ pageContext: { data } }: PagePropsInterface<ArticlePageInterface>) => {
  const title = data.title
  const excerpt = data.excerpt
  const image = data?.image?.url || ''
  const content = data?.contentHtml ?? data?.content ?? null
  const firstTag = data?.tags ? data.tags[0] : null
  const author = data?.author || null

  return (
    <Layout headerVariant="default" title={title} description={excerpt} image={image}>
      <Container
        variant="containerPrimary"
        sx={{
          py: [space.xl2],
        }}
      >
        {firstTag && (
          <Text
            sx={{
              fontSize: 2,
              lineHeight: '18px',
              textTransform: 'uppercase',
              color: 'txt',
            }}
          >
            {firstTag}
          </Text>
        )}
        <Heading
          as="h1"
          sx={{
            fontSize: 8,
            mb: [space.gridGap1],
            mt: [space.md1],
          }}
        >
          {title}
        </Heading>
        {excerpt && (
          <Text
            as="p"
            sx={{
              fontSize: [4],
              lineHeight: '40px',
              maxWidth: ['auto', null, 700],
              color: 'txt',
            }}
          >
            {excerpt}
          </Text>
        )}
        {/* {author && (
          <Text
            sx={{
              fontSize: 2,
              lineHeight: '18px',
              textTransform: 'uppercase',
              color: 'txt',
              mt: [space.l2],
            }}
          >
            By {author.name}
          </Text>
        )} */}
      </Container>
      {image && <HeroImageSection imageUrl={image} />}
      <Container
        variant="containerPrimary"
        sx={{
          pt: [space.xl3],
        }}
      >
        <BaseTypo>{content && <div dangerouslySetInnerHTML={{ __html: content }} />}</BaseTypo>
      </Container>
      <Container variant="containerPrimary">
        <Box
          sx={{
            mb: [space.xl2],
          }}
        >
          {author && author.bio && (
            <Fragment>
              <Heading
                as="h3"
                sx={{
                  fontSize: [6],
                  mb: [space.gridGap3],
                }}
              >
                About the Author
              </Heading>
              <Text
                as="p"
                sx={{
                  fontSize: [4],
                  lineHeight: ['40px'],
                  color: 'muted',
                }}
              >
                {author.bio}
              </Text>
            </Fragment>
          )}
        </Box>
        {/* <SocialShare /> */}
      </Container>
      {/* {relatedProducts && relatedProducts.length > 0 && (
        <Section label="Buy This Look">
          <Container>
            <GridTemplate
              data={relatedProducts}
              GridItem={(product: ProductItemType) => (
                <ProductItemSecondary key={product.id} product={product} />
              )}
              gridProps={{
                columns: [1, 2, 3],
                gap: [space.gridGap2],
              }}
            />
          </Container>
        </Section>
      )} */}
      <RecentArticles currentArticleHandle={data.handle} />
      {/* <ArticleRichSnippet
        data={{
          ...article.frontmatter,
          link: article.link,
        }}
      /> */}
    </Layout>
  )
}

export default Article
